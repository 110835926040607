import { useStaticQuery, graphql } from "gatsby";
export const useHeroImages = () => {
  const { images } = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: {
          relativePath: {
            in: [
              "staticPages/space-hero.jpg",
              "staticPages/desk-hero.jpg",
              "staticPages/ems-hero.jpg",
              "staticPages/mc-hero.jpg",
              "staticPages/home-hero.png",
              "staticPages/Migration_Meridian/Meridian_NewHero-1.jpg",
              "staticPages/Migration-Lucernex/Lx-NewHero.jpg",
              "staticPages/edm-hero.jpg",
              "staticPages/cmms_newhero.jpg"
            ]
          }
        }
      ) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImage: gatsbyImageData(
              placeholder: NONE,
              width:1000,
              quality: 70
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  `);

  return images?.nodes?.reduce((imagesObj,node)=>{  imagesObj[node.relativePath] = node.childImageSharp; return imagesObj;}, {});
};
