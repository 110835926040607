import React from "react"
import { LocalizedLink } from "src/components/commonComponents"
import rightArrow from "src/images/new-version-homepage/Arrow_Right.svg";
import leftArrowBlack from "src/images/new-version-homepage/Arrow_Left_black.svg";
import rightArrowBlack from "src/images/new-version-homepage/Arrow_right_black.svg";
import cityWhite from "../../static/staticPages/home-hero.png";
import rightArrowBlue from "src/images/new-version-homepage/Arrow_Right_blue.svg";
import rightArrowWhite from "src/images/new-version-homepage/Arrow_Right_white.svg";
import industryIcon from "src/images/new-version-homepage/industry.svg";
import industryIconWhite from "src/images/new-version-homepage/industry-white.svg";
import polygons from "src/images/new-version-homepage/polygons.png";
import StatisticImage from "src/images/new-version-homepage/statistic-image.png";
import arrowRightBlue from "src/images/new-version-homepage/multi-image/arrowRightBlue.svg";
import { optimizeLifeCycleData, productsList, statistics, data } from "src/rawData/newHomepage";
import { SEO, Picture } from "src/components/commonComponents";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import Support from "src/components/SupportHomepage";
import * as styles from "src/styles/homepage-resource.module.css";
import bookusersolidicon from "../../static/staticPages/book-user-solid.svg";
import bookssolidicon from "../../static/staticPages/books-solid.svg";
import buildingcirclearrowrightsolid from "../../static/staticPages/building-circle-arrow-right-solid.svg";
import buildingssolidicon from "../../static/staticPages/buildings-solid.svg";
import bullseyepointersolidicon from "../../static/staticPages/bullseye-pointer-solid.svg";
import chairofficesolidicon from "../../static/staticPages/chair-office-solid.svg";
import computersolidicon from "../../static/staticPages/computer-solid.svg";
import desktopsolidicon from "../../static/staticPages/desktop-solid.svg";
import houselaptopsolidicon from "../../static/staticPages/house-laptop-solid.svg";
import foldertreesolidicon from "../../static/staticPages/folder-tree-solid.svg";

import { useHeroImages } from "src/hooks/useHeroImages";
import { getPageDataJsonPath } from "src/utils";

const scrollMethod = (condition) => {
  let id = document.getElementById('products-home-scroll')
  id.scrollBy({
    left: condition === "right" ? 320 : condition === "left" ? -320 : '',
    behavior: "smooth",
  })
}

const HeroSection = (heroImages) => {
  return (
    <div style={{ background: 'radial-gradient(54.89% 54.89% at 50% 50%, #001C71 0%, #13091C 100%)' }} className="tw-relative">
      <Picture
        className="tw-absolute tw-w-full tw-h-full tw-top-[100px]"
        image={heroImages.heroImages['staticPages/home-hero.png']}
        rel="preload"
        alt="Outline of city buildings showcasing a unified built environment with Accruent's purpose-built operations software."
      />
      <div className="container section">
        <div className="tw-flex tw-flex-col tw-text-center tw-items-center">
          <h1 className=" tw-m-auto tw-pt-[50px] md:tw-pt-[170px] tw-pb-[40px] !tw-text-[80px] !tw-font-normal !tw-leading-[86px] !tw-tracking-[-1.64px] !tw-text-[#FFF] tw-max-w-[1014px]">Software Solutions for Your People, Places and Assets</h1>
          <p className="tw-max-w-[450px] tw-m-auto tw-text-[20.18px] tw-font-normal tw-leading-[27.3px] tw-tracking-[-0.42px] tw-text-[#FFF] tw-pb-[40px]">Unify your built environment with Accruent's purpose-built operations software</p>
          <LocalizedLink className="tw-mb-[50px] md:tw-mb-[170px]" to="/request-demo">
            <p className="tw-flex tw-gap-3 tw-items-center tw-bg-[#0E47DF] tw-p-[13px_6px_13px_19px] tw-rounded-[100px] hover-request-demo"><span className="tw-text-[19px] tw-font-normal tw-leading-[19.5px] tw-tracking-[-0.3px] tw-text-[#FFF]">Request a demo</span> <span className="tw-bg-[#001C71] tw-rounded-[20px]"><img src={rightArrow} className="tw-p-[10px]" alt="right-arrow" /></span></p>
          </LocalizedLink>
        </div>
      </div>
    </div>
  )
}


const LogoSlider = () => {
  return(
    <div className="tw-bg-[#222] tw-relative">
      <div className="container">
        <div className="tw-flex tw-flex-row tw-justify-start md:tw-justify-center tw-items-center tw-pt-[13px] tw-pb-[13px] xl:tw-gap-[100px] md:tw-gap-[60px] tw-gap-[20px] md:tw-overflow-hidden tw-overflow-auto">
        { data?.logoSlider?.logoList?.map((logo, index)=>
            <img key={index}src={logo?.url} alt={logo?.altText} loading="lazy"></img>
          )
          }
        </div>
      </div>

    </div>
  )
}


const OptimizeLifeCycle = () => {
  return (
    <div>
      <div style={{ background: ' linear-gradient(180deg, rgba(255, 255, 255, 0.09) 45.85%, rgba(14, 71, 223, 0.16) 100%), #FFF' }} className="tw-relative">
        <img src={polygons} className="tw-absolute tw-w-full tw-h-full" alt="polygons" loading="lazy"/>
        <div className="container section">
          <h2 className=" tw-pt-[50px] md:tw-pt-[140px] md:tw-w-[86%] !tw-text-[60px] !tw-font-medium !tw-leading-[73px] !tw-tracking-[-1.578px] tw-mb-[50px] md:tw-mb-[160px]">Smart Technologies for Smarter Spaces and Assets</h2>
          <div className="tw-flex md:tw-flex-row tw-flex-col tw-items-start">
            <div className="md:tw-mr-[280px] md:tw-sticky md:tw-top-[150px] tw-mb-[20px] md:tw-mb-0">
              <p className="tw-text-[20px] tw-font-normal tw-leading-[27px] tw-tracking-[-0.064px] tw-mb-[27px]">Learn how Accruent can empower you to optimize every aspect of your built environment's lifecycle </p>
              <LocalizedLink to="/solutions" className="tw-text-[#0E47DF] hover:tw-text-[#0E47DF] tw-flex tw-flex-row tw-items-center tw-gap-2 tw-w-max tw-border-solid tw-border-b tw-border-transparent hover:tw-border-solid hover:tw-border-b hover:tw-border-[#0E47DF] "><span>Explore</span><span><img src={rightArrowBlue} alt="right-arrow"/></span></LocalizedLink>
            </div>
            <div>
              {!!optimizeLifeCycleData?.length && optimizeLifeCycleData?.map((item, index) => {
                return (
                  <div className="tw-bg-[#FFF] tw-rounded-[5px] tw-mb-[30px] optimize-bg hover:tw-bg-[#0E47DF]">
                    <div className="tw-flex tw-flex-row tw-p-[50px_40px_50px_40px] tw-gap-[30px] tw-items-start">
                      <div className="tw-rounded-[6px] tw-p-[15px] tw-border tw-border-white tw-border-solid" >
                        <img src={item?.logo} alt={`${item?.altText}`} className="tw-w-[70px] industry-black" loading="lazy"/>
                        <img src={industryIconWhite} alt={`${item?.altText}-white`} className="tw-w-[70px] industry-white tw-hidden" loading="lazy"/>
                      </div>
                      <div>
                        <h3 className="!tw-text-[28px] !tw-font-normal !tw-leading-[33px] !tw-tracking-[0.084px] tw-mb-[20px]">{item?.title}</h3>
                        <p className="tw-text-[20px] tw-font-normal tw-leading-[27px] tw-tracking-[-0.064px]">{item?.body} </p>
                        <LocalizedLink to={item?.link} className="hover:tw-text-[#0E47DF] tw-text-[#0E47DF] optimize-link  tw-flex tw-flex-row tw-items-center tw-gap-2 tw-w-max tw-border-solid tw-border-b tw-border-transparent hover:tw-border-solid hover:tw-border-b hover:tw-border-[#FFF]"><span>{item?.linkText}</span><span>
                          <img src={rightArrowBlue} alt="right-arrow" className="arrow-blue"/>
                          <img src={rightArrowWhite} alt="right-white" className="tw-hidden arrow-white"/>
                          </span>
                          </LocalizedLink>

                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const ProductSection = () => {
  return (
    <div className="container section">
      <div className="tw-flex tw-flex-col tw-mt-[50px] md:tw-mt-[120px]">
        <div className="tw-flex  tw-flex-col md:tw-flex-row md:tw-mb-[100px] tw-mb-[50px]">
          <div className="md:tw-w-[60%]">
            <h2 className=" !tw-text-[60px] !tw-font-medium !tw-leading-[73px] !tw-tracking-[-1.578px]">Products designed to integrate your world</h2>
          </div>
          <div className="md:tw-w-[40%]">
            <p className=" tw-text-[24px] tw-font-normal tw-leading-[29px] tw-tracking-[0.05px]">Accruent software enables you to fully optimize the lifecycle of your assets and facilities, offering connected workflows, integrated experiences and data-backed insights across your built enivronment.</p>
          </div>
        </div>
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-[30px] tw-mb-[30px] tw-overflow-auto scroll-product md:tw-pb-[25px]" id='products-home-scroll'>
          {!!productsList?.length && productsList?.map((product, index) => {
            return (
              <div className="tw-bg-[#F5F4F3] tw-relative tw-min-h-[480px] tw-min-w-[305px]">
                <div className="tw-flex tw-flex-col tw-p-[50px_30px_30px_30px] tw-justify-between">
                  <div>
                    <img src={product?.logo} className="tw-mb-[50px] tw-max-h-[53px]" alt={product?.alt} loading="lazy"/>
                  </div>
                  <h3 className="!tw-text-[24px] !tw-font-normal !tw-leading-[29px] !tw-tracking-[0.05px] tw-mb-[20px]">{product?.title}</h3>
                  <p className="tw-text-[20px] tw-font-normal tw-leading-[27px] tw-tracking-[-0.064px]">{product?.description}</p>
                </div>
                <LocalizedLink to={product?.link} className="tw-text-[#0E47DF] hover:tw-text-[#0E47DF] tw-flex tw-flex-row tw-items-center tw-gap-2 tw-absolute tw-left-[32px] tw-bottom-[20px] tw-w-max tw-border-solid tw-border-b tw-border-transparent hover:tw-border-solid hover:tw-border-b hover:tw-border-[#0E47DF] "><span>{product?.linkText}</span><span><img src={rightArrowBlue} /></span></LocalizedLink>

              </div>
            )
          })}
        </div>
        <div className="tw-hidden md:tw-flex tw-flex-row tw-gap-[16px] tw-mb-[100px] tw-justify-end">
          <div style={{ border: '1px solid rgba(34, 34, 34, 0.30)'}} className="tw-rounded-[40px] tw-cursor-pointer">
            <img src={leftArrowBlack} alt="left-logo" className="tw-p-[16px]" onClick={() => scrollMethod('left')}/>
          </div>
          <div style={{ border: '1px solid rgba(34, 34, 34, 0.30)'}} className="tw-rounded-[40px] tw-cursor-pointer">
            <img src={rightArrowBlack} alt="right-logo" className="tw-p-[16px]" onClick={() => scrollMethod('right')}/>
          </div>
        </div>
        <div className="tw-flex tw-justify-center tw-flex-col tw-items-center tw-mb-[50px] md:tw-mb-[120px]">
          <p className="tw-text-[24px] tw-font-normal tw-leading-[29px] tw-tracking-[0.05px] tw-mb-[50px]">Ready to learn how Accruent can unify your business?</p>
          <LocalizedLink to="/request-demo">
            <p className="tw-flex tw-gap-3 tw-items-center tw-bg-[#0E47DF] tw-p-[13px_6px_13px_19px] tw-rounded-[100px] hover-request-demo"><span className="tw-text-[19px] tw-font-normal tw-leading-[19.5px] tw-tracking-[-0.3px] tw-text-[#FFF]">Request a demo</span> <span className="tw-bg-[#001C71] tw-rounded-[20px]"><img src={rightArrow} className="tw-p-[10px]" /></span></p>
          </LocalizedLink>
        </div>

      </div>
    </div>
  )
}

const StatiscsSection = () => {
  return (
    <div className="tw-relative tw-z-[1] ">
      <div className="tw-flex flex-row">
        <div style={{ background: 'linear-gradient(0deg, #002B7E -0.03%, #0E47DF 96.17%), var(--Hero, #0E47DF)' }} className="tw-w-full md:tw-w-1/2">
          <div className="tw-flex tw-flex-col tw-p-[30px] md:tw-p-[120px_55px_120px_55px]">
            <h2 className="tw-mb-[26px] !tw-text-[44px] !tw-font-normal !tw-leading-[48px] !tw-tracking-[0.119px] !tw-text-[#FFF]">
              Trusted by 5000+ businesses, retailers and manufacturers around the globe
            </h2>
            <p style={{ color: 'rgba(255, 255, 255, 0.70)' }} className="tw-text-[20px] tw-font-normal tw-leading-[27px] tw-tracking-[-0.064px] tw-mb-[60px]">What makes Accruent a market leader</p>
            <div className="tw-flex tw-flex-row tw-mb-[110px]">

              {statistics?.map((stats, index) => {
                return (
                  <div className="tw-flex tw-flex-col tw-relative" key={index}>
                    <hr style={{ borderLeft: '1px solid rgba(255, 255, 255, 0.55)' }} className="tw-h-full tw-absolute tw-top-[-22px]" />
                    <div className="tw-pl-[20px]">
                      <p className="tw-text-[44px] tw-font-normal tw-leading-[48px] tw-tracking-[0.119px] tw-mb-[18px] tw-text-[#FFF]">{stats?.metric}</p>
                      <p style={{ color: 'rgba(255, 255, 255, 0.70)' }} className="tw-text-[14px] tw-font-normal tw-leading-[21px] tw-tracking-[-0.045px]">{stats?.description}</p>

                    </div>
                  </div>
                )
              })}

            </div>
            <div>

            </div>
            <div className="">
            {/* <div className="tw-flex tw-flex-row tw-gap-[40px] tw-items-center">
              {statiscticsLogos?.map((stats, index) => {
                return (
                  <div key={index}>
                    <img src={stats?.logo} alt="stats-logo"/>
                  </div>
                )
              })}
            </div> */}
          </div>
          </div>
         
        </div>
        <div className="md:tw-block tw-hidden tw-w-1/2">
          <img src={StatisticImage} className="tw-w-full tw-h-full" alt="Two professionals in a modern office discussing business, representing trust by 5000+ customers globally." loading="lazy"/>
        </div>
      </div>
    </div>
  )
}


const Resource = () => {
  return (
    <div className={styles.resourceContainer}>
      <div className={`${styles.resourceHeadingWrapper} container`}>
        <h2 className={styles.resourceTitle}>{data?.resources?.title}</h2>
        <a href={data?.resources?.link?.url} className={styles.resourceLinkWrapper}>
          <span className={styles.resourceLinkText}>{data?.resources?.link?.text}</span>
          <img src={arrowRightBlue} alt='arrow button'></img>
        </a>
      </div>
      <div className={styles.resourceWrapper}>
        <div className={`${styles.resourceCardWrapper} container`}>
          <a href={data?.resources?.resourceList?.resource1?.link} className={styles.resourceCard}>
            <img className={styles.resourceCardImg} src={data?.resources?.resourceList?.resource1?.image?.url} alt={data?.resources?.resourceList?.resource1?.image?.altText} loading="lazy"/>
            <div className={styles.resourceCardTextWrapper}>
              <p className={styles.resourceCardTitle}>{data?.resources?.resourceList?.resource1?.title}</p>
              <p className={styles.serviceCardDesc}>{data?.resources?.resourceList?.resource1?.description}</p>
              <div className={styles.resourceCardBtnWrapper + " tw-static lg:tw-absolute tw-bottom-[30px]"}>
                <div className={styles.resourceCardBtn}>{data?.resources?.resourceList?.resource1?.button?.text}</div>
              </div>
            </div>
          </a>
          <a href={data?.resources?.resourceList?.resource2?.link} className={styles.resourceCard}>
            <img className={styles.resourceCardImg} src={data?.resources?.resourceList?.resource2?.image?.url} alt={data?.resources?.resourceList?.resource2?.image?.altText} loading="lazy"/>
            <div className={styles.resourceCardTextWrapper}>
              <p className={styles.resourceCardTitle}>{data?.resources?.resourceList?.resource2?.title}</p>
              <p className={styles.serviceCardDesc}>{data?.resources?.resourceList?.resource2?.description}</p>
              <div className={styles.resourceCardBtnWrapper + " tw-static lg:tw-absolute tw-bottom-[30px]"}>
                <div className={styles.resourceCardBtn}>{data?.resources?.resourceList?.resource2?.button?.text}</div>
              </div>
            </div>
          </a>
          <div className={styles.resourceMultiCardWrapper}>
            {
              data?.resources?.resourceList?.list?.map((item, index)=>
                 <a key={index} href={item?.link} className={styles.resourceMultiCard}>
                  <div>
                    <img className={styles.resourceMultiCardImg} src={item?.image?.url} alt={item?.image?.altText}></img>
                    <div className={styles.resourceCardBtnWrapper + " md:!tw-hidden !tw-flex tw-mt-[15px] tw-w-fit"}>
                        <div className={styles.resourceCardBtn}>{item?.button?.text}</div>
                    </div>
                  </div>
                  <div className={styles.resourceMultiCardTextWrapper}>
                    <p className={styles.resourceMultiCardText}>{item?.title}</p>
                    <div className={styles.resourceCardBtnWrapper + " md:!tw-flex !tw-hidden"}>
                      <div className={styles.resourceCardBtn}>{item?.button?.text}</div>
                    </div>
                  </div>
                </a>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}



const MultiImageBanner = () => {
  return (
    <div className={styles.multiImageContainer}>
      <div className={styles.multiImageEclipseWrapper}>
        <div className={styles.multiImageEclipse1}></div>
        <div className={styles.multiImageEclipse2}></div>
        <div className={styles.multiImageEclipse3}></div>
      </div>
      <div className={styles.multiImageWrapper}>
        <img className={styles.multiImage1} src={data?.multiImageBanner?.image1?.url} alt={data?.multiImageBanner?.image1?.altText} loading="lazy"></img>
        <img className={styles.multiImage2} src={data?.multiImageBanner?.image2?.url} alt={data?.multiImageBanner?.image2?.altText} loading="lazy"></img>
        <img className={styles.multiImage3} src={data?.multiImageBanner?.image3?.url} alt={data?.multiImageBanner?.image3?.altText} loading="lazy"></img>
        <img className={styles.multiImage4} src={data?.multiImageBanner?.image4?.url} alt={data?.multiImageBanner?.image4?.altText} loading="lazy"></img>
        <img className={styles.multiImage5} src={data?.multiImageBanner?.image5?.url} alt={data?.multiImageBanner?.image5?.altText} loading="lazy"></img>
        <img className={styles.multiImage6} src={data?.multiImageBanner?.image6?.url} alt={data?.multiImageBanner?.image6?.altText} loading="lazy"></img>
      </div>
      <div className={styles.multiImageTitleWrapper}>
        <h2 className={styles.multiImageTitle}>{data?.multiImageBanner?.title}</h2>
      </div>
      <div className={styles.multiImageDescWrapper}>
        <p className={styles.multiImageDesc}>{data?.multiImageBanner?.description}</p>
        <div className={styles.multiImageLinkWrapper}>
          <LocalizedLink to={data?.multiImageBanner?.link1?.url} className={styles.multiImageLink1Wrapper + " tw-w-max tw-border-solid tw-border-b tw-border-transparent hover:tw-border-solid hover:tw-border-b hover:tw-border-[#0E47DF]"}>
            <span className={styles.multiImageLink1Text}>{data?.multiImageBanner?.link1?.text}</span>
            <img src={arrowRightBlue} alt='Right arraow'></img>
          </LocalizedLink>
          <LocalizedLink to={data?.multiImageBanner?.link2?.url} className={styles.multiImageLink2Wrapper + " tw-w-max tw-border-solid tw-border-b tw-border-transparent hover:tw-border-solid hover:tw-border-b hover:tw-border-[#0E47DF]"}>
            <span className={styles.multiImageLink2Text}>{data?.multiImageBanner?.link2?.text}</span>
            <img src={arrowRightBlue} alt='Right arraow'></img>
          </LocalizedLink>
        </div>
      </div>
    </div>
  )
}

const OGMarkup = getOGMarkup('Accruent','homepage');


const NewHomePage = () => {
  const heroImages = useHeroImages();
  const pageDataPath = getPageDataJsonPath('/new-homepage')
  return (
    <>
     <SEO
        meta={[
          { 'name': 'robots', 'content': 'noindex' },
          { 'name': 'description', 'content': 'Accruent\'s software helps workplace & asset management organizations unify their built environments for better management of people, places, and resources.' }
        ]}
        pageUrl="/new-homepage"
        OGMarkup={OGMarkup}
        heroImage={heroImages['staticPages/home-hero.png']}
        preloads= {[pageDataPath]}
      />
      <HeroSection heroImages={heroImages}/>
      <LogoSlider />
      <OptimizeLifeCycle />
      <ProductSection />
      <StatiscsSection />
      <MultiImageBanner />
      <Support />
      <Resource />
    </>
  )
}
export default NewHomePage;