import industryIcon from "src/images/new-version-homepage/industry-white.svg";
//import mcLogo from "src/images/new-version-homepage/Accruent-Maintenance-Connection-Color.png";
import mcLogo from "../../static/staticPages/mc-logo.png"
import emsLogo from "src/images/new-version-homepage/Accruent-EMS-Color.png";
import lucernexLogo from "src/images/new-version-homepage/Accruent-Lucernex-Color.png";
import meridianLogo from "src/images/new-version-homepage/Accruent-Meridian-Color.svg";
import famisLogo from "src/images/new-version-homepage/Accruent-FAMIS-360-Color.svg";
import tmsLogo from "src/images/new-version-homepage/Accruent-TMS-Color.svg";
import GoogleImage from "src/images/new-version-homepage/Google.png";
import CanonImage from "src/images/new-version-homepage/canon.svg";
import TelsaImage from "src/images/new-version-homepage/Tesla.svg";
import HubSpotImage from "src/images/new-version-homepage/HubSpot.svg";
import AdobeImage from "src/images/new-version-homepage/Adobe_Corporate_logo.svg";
import resource1 from "src/images/new-version-homepage/resources/resource1.jpg";
import resource2 from "src/images/new-version-homepage/resources/resource2.jpg";
import resource3 from "src/images/new-version-homepage/resources/resource3.jpg";
import resource4 from "src/images/new-version-homepage/resources/resources4.jpg";
import resource5 from "src/images/new-version-homepage/resources/resource5.jpg";
import logo1 from "src/images/new-version-homepage/banner_logo/logo1.png";
import logo2 from "src/images/new-version-homepage/banner_logo/logo2.png";
import logo3 from "src/images/new-version-homepage/banner_logo/logo3.png";
import logo4 from "src/images/new-version-homepage/banner_logo/logo4.png";
import logo5 from "src/images/new-version-homepage/banner_logo/logo5.png";
import logo6 from "src/images/new-version-homepage/banner_logo/logo6.png";
import multiImage1 from "src/images/new-version-homepage/multi-image/multiImage1.png";
import multiImage2 from "src/images/new-version-homepage/multi-image/multiImage2.png";
import multiImage3 from "src/images/new-version-homepage/multi-image/multiImage3.png";
import multiImage4 from "src/images/new-version-homepage/multi-image/multiImage4.png";
import multiImage5 from "src/images/new-version-homepage/multi-image/multiImage5.png";
import multiImage6 from "src/images/new-version-homepage/multi-image/multiImage6.png";
// import redEyeLogo from "../../static/home/Products/RedEye-Accruent-Logo.jpg";
import redEyeLogo from "src/images/hackathon/logos/color/Logo-Redeye-color.png";
import bookusersolidicon from "../../static/staticPages/book-user-solid.svg";
import bookssolidicon from "../../static/staticPages/books-solid.svg";
import buildingcirclearrowrightsolid from "../../static/staticPages/building-circle-arrow-right-solid.svg";
import buildingssolidicon from "../../static/staticPages/buildings-solid.svg";
import bullseyepointersolidicon from "../../static/staticPages/bullseye-pointer-solid.svg";
import chairofficesolidicon from "../../static/staticPages/chair-office-solid.svg";
import computersolidicon from "../../static/staticPages/computer-solid.svg";
import desktopsolidicon from "../../static/staticPages/desktop-solid.svg";
import houselaptopsolidicon from "../../static/staticPages/house-laptop-solid.svg";
import foldertreesolidicon from "../../static/staticPages/folder-tree-solid.svg";
import userheadsetsolidicon from "../../static/staticPages/user-headset-solid.svg";
import userhelmetsolidicon from "../../static/staticPages/user-helmet-safety-solid.svg";
import usertiesolidicon from "../../static/staticPages/user-tie-solid.svg";

export const optimizeLifeCycleData = [
  {
   title: 'Computerized Maintenance Management Systems',
   body: 'Transform your facility and asset maintenance by getting a handle on work orders, increasing reliability with preventative maintenance, streamlining parts ordering and management, and maintaining regulatory compliance.',
   linkText: 'Explore',
   link: '/solutions/cmms-software' ,
   logo: computersolidicon,
   altText: 'Computer icon representing the industry'
  },
  {
    title: 'Engineering Document Management Software',
    body: "Eliminate the headache of collaborating on engineering documents, even with external users, by having a single source of truth that’s purpose-built for today's engineering professionals.",
    linkText: 'Explore',
    link: '/solutions/engineering-document-management-software' ,
    logo: foldertreesolidicon,
    altText: 'Folder tree icon representing the industry'
   },
   {
    title: 'Integrated Workplace Management Software',
    body: 'Manage your entire workplace lifecycle, from site-selection and construction, to lease and space management, to increase productivity, slash costs, enable collaboration and facilitate data-driven decision-making.',
    linkText: 'Explore',
    link: '/solutions/iwms-software',
    logo: buildingcirclearrowrightsolid,
    altText: 'Right arrow icon representing the industry'
   },
   {
    title: 'Space Planning Software',
    body: 'Uncover additional square footage, manage space allocations and identify areas for savings with our space planning software.',
    linkText: 'Explore',
    link: '/solutions/space-planning-software',
    logo: buildingssolidicon ,
    altText: 'Building icon representing the industry'
   }
   ,
   {
    title: 'Desk and Room Booking Software',
    body: 'Transform how people use your spaces by seamlessly connecting your buildings, spaces and events with enterprise-class, intuituve space, desk and resource scheduling solutions.',
    linkText: 'Explore',
    link: '/solutions/desk-room-booking-software',
    logo: chairofficesolidicon ,
    altText: 'Office chair icon representing the industry'
   }
   ,
   {
    title: 'Construction Project Management Software',
    body: 'Construction project management software designed to keep your projects on schedule and within budget by connecting teams and enabling faster, more informed, decision-making.',
    linkText: 'Explore',
    link: '/solutions/construction-project-management-software',
    logo: userhelmetsolidicon ,
    altText: 'Safety helmet icon representing the industry'
   }
]
export const productsList = [
  {
    logo:emsLogo,
    title: "Desk, Room and Event Scheduling Made Easy",
    description:
      "Workplace management software simplifies desk, meeting and resource scheduling for your office or campus.",
    link: "/products/ems",
    linkText: "Explore EMS",
    alt: "Accruent EMS Logo"
  },
  {
    logo: lucernexLogo,
    title: "Real Estate Management Software",
    description:
      "Market-leading real estate software solutions manage lease, site planning and construction complexities.",
    link: "/products/lucernex",
    linkText: "Explore Lucernex",
    alt: "Accruent Lucernex Logo"
  },
  {
    logo: redEyeLogo,
    title: "Engineering Document Management Made Easy",
    description: "Workflow-based document collaboration, when and where you need it, without per-user licensing.",
    link: "/products/redeye",
    linkText: "Explore RedEye",
    alt: "Accruent Redeye Logo"
  },
  {
    logo: mcLogo,
    title: "Multi-site, Multi-Industry CMMS Software",
    description:
      "CMMS software reduces downtime, predicts maintenance needs and managers asset and equipment inventory.",
    link: "/products/maintenance-connection",
    linkText: "Explore Maintenance Connection",
    alt: "Accruent Maintenance Connection Logo"
  },
  {
    logo: meridianLogo,
    title: "EDMS for Highly Regulated Environments",
    description: "On-premise document management with collaborative workflows and tight access controls.",
    link: "/products/meridian",
    linkText: "Explore Meridian",
    alt: "Accruent Meridian Logo"
  },
  {
    logo: tmsLogo,
    title: "Healthcare CMMS Software for Maximum Flexibility",
    description:
      "Healthcare CMMS improves regulatory compliance while optimizing biomed and facility management operations.",
    link: "/products/tms",
    linkText: "Explore TMS",
    alt: "Accruent TMS Logo"
  },
  {
    logo: famisLogo,
    title: "Facilities Management Software for Better Space Management",
    description: "Maintenance, space, and energy management for Education, Corporate, and Retail environments.",
    link: "/products/famis-360",
    linkText: "Explore FAMIS 360",
    alt: "Accruent Famis Logo"
  },
]
export const statistics = [
  {
    metric: '55+',
    description: 'Masters Gallery achieved an 85% planned maintenance to 15% unplanned maintenance split.'
  },
  {
    metric: '$50m',
    description: 'Minnesota State saved $50M and eliminated 60,000 square feet of wasted space with EMS.'

  },
  {
    metric: '25%',
    description: "Reduction in equipment downtime giving Chuy's time back to focus on customer experience"
  }
]

export const statiscticsLogos = [
  {
    logo: GoogleImage,
  },
  {
    logo: CanonImage,
  },
  {
    logo: TelsaImage,
  },
  {
    logo: HubSpotImage,
  },
  {
    logo: AdobeImage,
  },
]

export const data = {
  resources: {
    title: "Some resources you might find interesting",
    link: {
      url: '/resources',
      text: "Explore Accruent resources"
    },
    resourceList: {
      resource1: {
        image: {
          url: resource1,
          altText: 'Engineering Document Management System'
        },
        title: "What Is an EDMS? Engineering Document Management System",
        description:"An engineering document management system is a type of electronic DMS focused specifically on an organization's engineering drawings, documents, and data.",
        button: {
          text: 'Knowledgehub',
          link: '/resources/knowledge-hub/what-is-an-engineering-document-management-system'
        }
      },
      resource2: {
        image: {
          url: resource2,
          altText: 'Capital Lease Accounting 101'
        },
        title: "Capital Lease Accounting 101",
        description:"Learn about capital lease accounting including key differences from operating leases, impact on balance sheets, and compliance with accounting standards.",
        button: {
          text: 'Brochure',
          link: '/resources/knowledge-hub/capital-lease-accounting'
        }
      },
      list: [
        {
          image: {
            url: resource3,
            altText: 'What is Healthcare Facilities Management (HFM)?'
          },
          title: "What is Healthcare Facilities Management (HFM)?",
          // description:"Hear from Encore Wire's Kyler Keel about how Maintenance Connection enables more predictive operations and simplifies asset & inventory management.",
          button: {
            text: 'Blog',
            link: '/resources/blog-posts/what-healthcare-facilities-management-hfm'
          }
        },
        {
          image: {
            url: resource4,
            altText: 'What Is Lease Management?'
          },
          title: "What Is Lease Management?",
          // description:"Hear from Encore Wire's Kyler Keel about how Maintenance Connection enables more predictive operations and simplifies asset & inventory management.",
          button: {
            text: 'Knowledgehub',
            link: '/resources/knowledge-hub/what-is-lease-management'
          }
        },
        {
          image: {
            url: resource5,
            altText: 'Four Important Factors for Site Selection'
          },
          title: "Four Important Factors for Site Selection",
          // description:"Hear from Encore Wire's Kyler Keel about how Maintenance Connection enables more predictive operations and simplifies asset & inventory management.",
          button: {
            text: 'Blog',
            link: '/resources/blog-posts/four-important-factors-site-selection'
          }
        }
      ]
    }
  },
  logoSlider: {
    logoList: [
      {
        url: logo1,
        altText: 'Encore Wire company logo'
      },
      {
        url: logo2,
        altText: 'City of Seattle logo'
      },
      {
        url: logo3,
        altText: 'CoOp Group logo'
      },
      {
        url: logo4,
        altText: "L'Oreal company logo"
      },
      {
        url: logo5,
        altText: 'The New York Times logo'
      },
      {
        url: logo6,
        altText: 'Alaska Airlines company logo'
      }
    ]
  },
  multiImageBanner: {
    title: "We're building a more connected future where your people, systems and data work synergistically to drive informed decision-making, operational excellence and business growth.",
    description: "As the world's leading provider of workplace and asset management software for unifying the built environment, our solutions not only deliver results, but also illuminate possibilities you couldn't see before. ",
    link1: {
      text: "What We Do",
      url: '/about-us/why-accruent'
    },
    link2: {
      text: "About Accruent",
      url: '/about-us'
    },
    image1: {
      url: multiImage1,
      altText: "An employee working diligently on his computer, showcasing a modern and connected workplace environment."
    },
    image2: {
      url: multiImage2,
      altText: "A fruit sorting manufacturing plant, highlighting automation and efficiency in the food production industry."
    },
    image3: {
      url: multiImage3,
      altText: "A manufacturing conveyor belt in a plant, illustrating industrial automation and production efficiency."
    },
    image4: {
      url: multiImage4,
      altText: "A busy train station filled with commuters, representing urban connectivity and public transportation."
    },
    image5: {
      url: multiImage5,
      altText: "An engineer focused on a project, symbolizing innovation and technical expertise in engineering fields."
    },
    image6: {
      url: multiImage6,
      altText: "A steel worker operating in a factory, demonstrating the strength and productivity of the manufacturing sector."
    },

  },
  support: {
    title: "Our software is already easy to use. But we're still here for you",
    description: "We're here to help your whole team stay ahead of the curve as you grow.",
    menu: [
      {
        id: 1,
        title: "Customer Support",
        info: {
          title: "Expert Support at Your Service",
          desc: "Experience unparalleled customer support with Accruent. Our dedicated team is committed to ensuring your success, providing you with tailored solutions and expert guidance every step of the way. Whether you're facing a complex challenge or need quick answers, we're here to help you maximize the value of your Accruent software. Contact us today and let us transform your experience into one of effortless efficiency and productivity.",
          link1: {
            text: "Learn more",
            url: "/services-support/customer-support"
          },
          link2: {
            text: "Contact customer support",
            url: "/"
          },
          logo: desktopsolidicon,
          altText: 'Desktop icon'
        }
      },
      {
        id: 2,
        title: "Professional Services",
        info: {
          title: "Professional Services, Exceptional Outcome",
          desc: "Unlock the full potential of your Accruent software with our Professional Services team. Our experts are dedicated to optimizing your business processes, ensuring seamless implementation, and providing comprehensive training tailored to your unique needs. With Accruent's Professional Services, you gain a partner in progress—equipping your team for success, driving operational excellence, and delivering measurable results that propel your business forward.",
          link1: {
            text: "Learn more",
            url: "/services-and-support/professional-services"
          },
          link2: {
            text: "Contact customer support",
            url: "/"
          },
          logo: usertiesolidicon,
          altText: 'User tie icon'
        }
      },
      {
        id: 3,
        title: "Technical Support",
        info: {
          title: "Technical Support That Empowers Your Success",
          desc: "Navigate any technical challenge with confidence, thanks to Accruent's world-class Technical Support. Our responsive and knowledgeable team is equipped to provide you with swift, effective solutions, ensuring minimal downtime and optimal performance of your Accruent solutions. From troubleshooting to proactive advice, we're here to support your business's technological needs and help you achieve continuous operational excellence.",
          link1: {
            text: "Learn more",
            url: "/services-support/customer-support"
          },
          link2: {
            text: "Contact customer support",
            url: "/"
          },
          logo: userheadsetsolidicon,
          altText: 'User headset icon'
        }
      },
      {
        id: 4,
        title: "Accruent Academy",
        info: {
          title: "Accruent Academy: Elevate Your Expertise",
          desc: "Step into Accruent Academy and unlock the door to mastering our software solutions. Our comprehensive training programs are designed to enhance your skills, increase productivity, and leverage the full power of your Accruent tools. Whether you're new to our products or seeking to deepen your expertise, Accruent Academy provides a flexible learning environment with courses, certifications, and resources tailored to fit your schedule and learning style. Empower your team with the knowledge to succeed – join Accruent Academy today.",
          link1: {
            text: "Learn more",
            url: "/services-and-support/professional-services/training-services"
          },
          link2: {
            text: "Contact customer support",
            url: "/"
          },
          logo: bookssolidicon,
          altText: 'Book icon'
        }
      },
      {
        id: 5,
        title: "Resource Hub",
        info: {
          title: "Accruent's Resource Hub: Insights at Your Fingertips",
          desc: "Welcome to Accruent's Resource Hub, where information meets innovation. Dive into a wealth of resources, from in-depth articles and case studies to webinars and whitepapers, all crafted to help you navigate the complexities of your industry. Our Resource Hub is designed to empower you with cutting-edge insights and best practices that drive performance and growth. Stay ahead of the curve, refine your strategies, and achieve operational excellence with the expert knowledge you'll find right here at Accruent's Resource Hub.",
          link1: {
            text: "Learn more",
            url: "/resources"
          },
          link2: {
            text: "Contact customer support",
            url: "/"
          },
          logo: bookusersolidicon,
          altText: 'Book user icon'
        }
      },
      {
        id: 6,
        title: "Interactive Demos",
        info: {
          title: "Interactive Demos: Experience Accruent in Action",
          desc: "Discover the power and simplicity of Accruent's solutions with our Interactive Demos. Get a firsthand look at how our software can streamline your operations, enhance efficiency, and support your strategic goals. These self-guided tours allow you to explore features, functionalities, and the intuitive interface of our tools at your own pace. Experience the innovation that drives success across industries and see why organizations trust Accruent to manage their physical resources. Start your interactive journey today and envision the impact on your business.",
          link1: {
            text: "Learn more",
            url: "/resources"
          },
          link2: {
            text: "Contact customer support",
            url: "/resources"
          },
          logo: usertiesolidicon,
          altText: 'Book user icon'
        }
      }
    ]
  }
}