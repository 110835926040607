import React, {useState} from "react";
import * as styles from "src/styles/homepage-resource.module.css";
import { data } from "src/rawData/newHomepage";
import arrowRight from "src/images/new-version-homepage/Arrow_Right.svg";
import rightArrowBlue from "src/images/new-version-homepage/Arrow_Right_blue.svg";
import supportLogo from "src/images/new-version-homepage/supportLogo.svg";
import { LocalizedLink } from "./commonComponents";
const Support = () => {
  const [info, setInfo] = useState(data?.support?.menu[0])
  return (
    <div className={styles.supportContainer}>
      <div className={styles.supportWrapper}>
        <div className={styles.supportHeadWrapper}>
          <div className={styles.supportTitleWrapper}>
            <h2 className={styles.supportTitle}>{data?.support?.title}</h2>
          </div>
          <p className={styles.supportDesc}>{data?.support?.description}</p>
        </div>
        <div className={styles.supportDetailContainer}>
          <div className={styles.supportLeftWrapper}>
            <div className={styles.supportMenuWrapper}>
              {
                data?.support?.menu?.map((item, index) => (
                  <div key={index} onClick={() => setInfo(item)} className={styles.supportMenu + " md:tw-min-w-[362px]"}>
                    {(item?.id == info?.id) && <img src={arrowRight} alt='Right arrow'></img>}
                    <p className={styles.supportMenuTitle + "  tw-mb-0 tw-whitespace-nowrap"}>{item?.title}</p>
                  </div>
                ))
              }
            </div>
          </div>
          <div className={styles.supportRightWrapper}>
            <div className={styles.supportInfoWrapper}>
              <div className={styles.supportInfo}>
                <div className={styles.supportInfoTitleWrapper}>
                  <img src={info?.info?.logo} alt={info?.info?.altText} style={{height:'49px', width:'58px'}} />
                  <h3 className={styles.supportInfoTitle}>{info?.info?.title}</h3>
                </div>
                <p className={styles.supportInfoDesc}>{info?.info?.desc}</p>
              </div>
              <div className={styles.supportInfoLinkWrapper}>
                <LocalizedLink to={info?.info?.link1?.url} className={styles.supportInfoLink1 + " tw-w-max tw-border-solid tw-border-b tw-border-transparent hover:tw-border-solid hover:tw-border-b hover:tw-border-[#0E47DF]"}>
                  <span className={styles.supportInfoLink1Text}>{info?.info?.link1?.text}</span>
                  <img src={rightArrowBlue} alt='Right arrow'/>
                </LocalizedLink>
             
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Support;